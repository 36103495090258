import React from 'react';
import logo from './assets/logo.png'; // Certifique-se de que o caminho está correto

function App() {
  return (
    <div className="App" style={styles.app}>
      <header className="App-header" style={styles.header}>
        <img src={logo} className="App-logo" alt="logo" style={styles.logo} />
        <h1 style={styles.welcome}>Boas Vindas ao idtrue.com.br!</h1>
      </header>
    </div>
  );
}

const styles = {
  app: {
    textAlign: 'center',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: '10vmin',
    pointerEvents: 'none',
  },
  welcome: {
    color: '#333',
    fontSize: '24px',
    marginTop: '20px',
  },
};

export default App;
